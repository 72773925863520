module.exports = {
	language: {
		name: '中文'
	},
	header: {
		// title: 'UBCA Genetic Analysis',
		title: 'Canhelp CRC MSl',
		version: 'Version No.',
		changepwd: 'Change Your Password',
		out: 'Sign Out',
		inter:'Network Connection Error'
	},
	footer: {
		loading: 'Loading…',
		// loadingsuccess: 'Complete',
		loadingsuccess: 'No results found...',
	},
	error: {
		internet: 'Please check your network connection!',
		time: "Please ensure that your computer's time is synchronized with the internet time server!",
		nowInternet: 'Please check your network connection!',
		requestTimeout: 'Request timed out!',
		loginTimeout: 'Session expired, please relogin!',
		serveError: 'Server Error!',
		versionTimeout: 'Update required, please run as administrator!',
		loginFailed: 'Session timed out, please sign in again!',
	},
	confirm: {
		tip: 'Tip',
		sure: 'OK',
		cancel: 'Cancel'
	},
	menu: {
		introduce: 'Software Intro',
		addlist: 'Upload Sample',
		list: 'Analysis Record',
		about: 'Software Info',
		record: 'Log Record',
		handleRecord: 'Action Log',
		loginRecord: 'Sign in Log',
	},
	introduce: {
		title: {
			pre: 'Hello',
			next: 'Welcome'
		},
		p1: 'On this page, user could select qualified WSl image sample to upload for ananalysis.',
		p2: 'On this page, user could view and search for the completed analysis record.',
		p3: 'On this page, user could view information about the software.',
		p4: 'Includes the user sign in log and operational log, for tracing information.'
	},
	addlist: {
		uploadEm: 'Click Here to Upload Your Sample',
		index:'No.',
		fileName:'File Name',
		size:'File Size',
		progress:'Uploading Progress',
		status: 'Status',
		action:'Action',
		// 进度条状态以及提示语
		status1:'Awaiting',
		status2:'Uploading Complete',
		status3:'Uploading Fail',
		status4:'Analyzing',
		status5:'Complete Analysis',
		status6:'Paused',
		status7:'Receiving',
		status8: 'Uploading',
		// 移除操作
		deleteTip:'Delete Files',
		deleteText:'Deleted files are not retrievable, are you sure you want todelete it?',
		sure:'Yes',
		cancel:'Cancel',
		
		
		// uploadSize:'Supported formats are .xls and .xlsx, file name ≤ 50 characters, file size < 1MB!',
		// sampleFile:'Sample file:',
		// reUpload:'Re-upload',
		// submitData:'Submit data: ',
		// submitDataText:'Sample identification can be edited, default marker will be used if emptied, click Submit to create analysis record for valid sample data.',
		//    sampleIndex:'Sample No.',
		// fileName:'File name',
		// marker:'Sample identification',
		// ic:'Is the IC valid?',
		// yes:'Yes',
		// no:'No',
		// submit:'Submit',
		// file:'Please upload a file!',
		// successTip:'Analysis record successfully created, redirecting to analysis record list!',
		// fileType:'Invalid file type!',
		// fileSize:'Content uploaded should be < 1MB!',
		// fileLength:"Sample data file name can't exceed 50 characters!",
		// uploaded:"You've already uploaded the sample data file, do you want to upload again?"
	},
	list: {
		fileText: 'Enter the File Name',
		selectResult: 'Select Analysis Outcome',
		selectStatus:'Select Analysis Status',
		startDate: 'Starting Date',
		endDate: 'Ending Date',
		batchDownload: 'Batch Download',
		sureDownload: 'Confirm Batch Download',
		index: 'No.',
		fileName: 'File Name',
		score: 'Score',
		result: 'Analysis Result',
		submitTime: 'Submission time',
		analysisTime: 'Analysis Time',
		status:'Status',
		size: 'Size',
		action: 'Action',
		preview:'Preview',
		delete:'Delete',
		download: 'Download',
		// 移除操作
		deleteTip:'Delete Files',
		deleteText:'Deleted files are not retrievable, are you sure you want todelete it?',
		sure:'Yes',
		search: 'Search',
		cancel:'Cancel',
		select:'Please select the files you want to download'

		// startDateText:'Choose to submit starting date',
		// endDateText:'Choose to submit ending date',
		// refresh: 'Refresh',
		// updateNumber: 'Update time',
		// analysisStatus: 'Analysis status',
		// completed: 'Completed',
		// submitted: 'Submitted',
		// falied:'Failed',
		// action: 'Action',
		// view: 'View',
		
		// close: 'Close',
		// edit: 'Edit',
		// finish: 'Finish',
		// deleteSure: 'Are you sure you want to delete this request?',
		// deleted: 'Deleted!',
		// downloadFileSuccess:'Download complete!',
		// downloadSuccess: 'Download complete, please select the location to save the file!',
		// downloadFailed: 'Download failed!',
		// loading: 'Loading…',
		// unavailable: 'Data currently unavailable',
		// noPermission:'You do not have permission to process this application form!',
	},
	about: {
		name: 'Product Name:',
		model: 'Specification:',
		version: 'Release Version:',
		fullVersion: 'Complete Version:',
		manufacturer: 'Manufacturer:',
		ch: 'Canhelp Genomics Co., Ltd. (Suzhou)',
		companyAddressTitle: 'Address:',
		companyAddress: '1st and 2nd Floor, Building 8, No.26 Jinxing Road,Yejingongyeyuan (infeng Town), Zhangjiagang',
		manufacturerAddress: 'Manufacturing Address:',
		building1: '1st and 2nd Floor, Building 8, No.26Jinxing Road, Yejingongyeyuan (infeng Town), Zhangjiagang',
	},
	record: {
		pleaseSelect: 'Select Operation Type',
		startDate: 'Starting Date',
		endDate: 'Ending Date',
		index: 'No.',
		ip: 'User IP',
		actionType: 'Action Type',
		time: 'Action Time',
		clientInfo: 'Client Information',
		signIn:'Sign In',
		signOut:'Sign Out',
		desc: 'Action Description',
		search: 'Search',
		upload:'Upload Sample',
		cancelUp:'Upload Cancel',
		verify:'Verify File',
		preview:'Preview Result',
		download:'Download Result',
		dele:'Delete'
		
		
		// chooseDate: 'Choose a date',
		// choose: 'Please choose',
		// signIn: 'Sign in',
		// refreshToken: 'Renew certificates',
		// signOut: 'Sign out',
		// search: 'Search',
		// type: 'Action type',
		// create: 'Create analysis record',
		// edit: 'Edit request',
		// upload: 'Upload data',
		// stopUpload: 'Stop uploading',
		// downResult: 'Download analysis result',
		// deleteRequest: 'Delete analysis record',
		// requestNumber: 'Request number',
	},
	notFound: {
		h1: 'Feel sorry! The page to visit does not exist',
		h2: 'Empower doctors and give patients hope',
		p: 'automatic jump',
		span: 'You can also:',
		gobrow: 'Browse the official website',
		goBack: 'go back to the last page'
	}
}