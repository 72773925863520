/**
 * writen by jonah 2019年3月13日14:18:33
 */

import httpRequest from '../request.js'
import global from '../common.vue';

var basePath = global.baseUrl;
export function FunctionGet(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: 'get',
		params: obj.params
	})
}
//导出文件
export function FunctionDownFile(obj) {
	return httpRequest({
		url: basePath + obj.url,
		method: 'get',
		responseType: "blob",
		timeout: 120000
	})
}

//预览文件
export function FunctionFileview(obj) {
	return httpRequest({
		url: basePath + obj.url,
		method: 'get',
		responseType: obj.responseType,
		timeout: 120000,
	})
}
//导出文件
export function FunctionDownFilePost(obj) {
	return httpRequest({
		url: basePath + obj.url,
		method: 'post',
		responseType: "blob",
		timeout: 120000
	})
}
//导出文件带参
export function FunctionDownFilePostParams(obj) {
	return httpRequest({
		url: basePath + obj.url,
		method: 'post',
		responseType: "blob",
		timeout: 120000,
		data: obj.params
	})
}
//导入文件
export function FunctionImportFile(obj) {
	return httpRequest({
		url: basePath + obj.url,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		timeout: 120000,
		data: obj.params,
		onUploadProgress:obj.onUploadProgress,
		signal: obj.signal
	})
}
export function FunctionPost(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: "post",
		data: obj.params,
	})
}
// 估算
export function FunctionCalcPost(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: "post",
		data: obj.params,
		timeout: 300000,
	})
}
// 附件上传process
export function FunctionPostProcess(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: "post",
		data: obj.params,
		timeout: 86400000,
		onUploadProgress: obj.onUploadProgress
	})
}
export function FunctionFormPost(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: "post",
		data: obj.params,
		processData: false,
		headers: {
			'content-type': 'multipart/form-data'
		}

	})
}
export function FunctionPut(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: "put",
		data: obj.params
	})
}
export function FunctionDelete(obj) { //
	return httpRequest({
		url: basePath + obj.url,
		method: "delete",
		data: obj.params
	})
}
export function FunctionGet1(obj) {
	return httpRequest({
		url: obj.url,
		method: 'get',
	})
}
export function FunctionPost1(obj) { //
	return httpRequest({
		url: obj.url,
		method: "post",
		data: obj.params
	})
}
