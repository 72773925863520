module.exports = {
	language: {
		name: 'English'
	},
	header: {
		title: '结直肠癌数字病理图像微卫星不稳定性分析软件',
		version: '版本号：',
		changepwd: '修改密码',
		out: '退出登录',
		inter: '网络连接错误'
	},
	footer: {
		loading: '加载中…',
		loadingsuccess: '加载完成',
	},
	error: {
		internet: '请检查您的网络！',
		time: '请检查您的电脑时间是否与北京时间同步！',
		nowInternet: '请检查当前网络！',
		requestTimeout: '请求超时',
		loginTimeout: '登录失效，请重新登录',
		serveError: '服务器错误',
		versionTimeout: '软件版本需更新，请以管理员身份运行本程序',
		loginFailed: '登录失效, 请重新登录！',
	},
	confirm: {
		tip: '提示',
		sure: '确定',
		cancel: '取消'
	},
	menu: {
		introduce: '软件介绍',
		addlist: '上传文件',
		list: '分析结果',
		about: '关于本软件',
		record: '日志记录',
		handleRecord: '操作日志',
		loginRecord: '登录日志',
	},
	introduce: {
		title: {
			pre: '您好',
			next: '欢迎使用结直肠癌数字病理图像微卫星不稳定性分析软件'
		},
		p1: '在本界面中，用户选择满足要求病理WSI样本图像文件进行上传。',
		p2: '在本界面中，用户可查询所有分析已完成文件的分析结果。',
		p3: '在本界面中，用户可查看软件详细版本等信息。',
		p4: '包括用户的登录日志和操作日志，用于追溯信息。'
	},
	addlist: {
		uploadEm: '点击上传',
		index: '序号',
		fileName: '文件名',
		size: '文件大小',
		progress: '上传进度',
		status: '状态',
		action: '操作',
		// 进度条状态以及提示语
		status1: '等待中',//--5
		status2: '上传完成',//--2
		status3: '上传失败',//--3
		status4: '分析中',
		status5: '分析完成',//--2
		status6: '已暂停',
		status7: '接收中',
		status8: '上传中',//--1
		// 移除操作
		deleteTip:'删除文件',
		deleteText:'删除的文件暂不支持找回，确定要删除当前文件吗？',
		sure:'删除',
		cancel:'取消',


		// uploadSize:'支持xls和xlsx格式，且文件名≤50位，文件大小<1MB',
		// sampleFile:'样本文件：',
		// reUpload:'重新上传',
		// submitData:'提交数据：',
		// submitDataText:'样本标识可编辑，清空后默认使用初始标识信息，点击提交后为满足要求的样本数据创建分析记录。',
		// sampleIndex:'样本号',
		// fileName:'文件名',
		// marker:'样本标识',
		// ic:'IC是否满足',
		// yes:'是',
		// no:'否',
		// submit:'提交',
		// file:'请上传文件！',
		// successTip:'分析记录创建成功，跳转至分析记录列表!',
		// fileType:'不正确的文件格式',
		// fileSize:'上传内容需小于1M',
		// fileLength:'样本数据文件的文件名长度不能超过50位',
		// uploaded:'您已上传样本数据文件，是否确定重新上传?',
	},
	list: {
		fileText: '输入文件名称',
		selectResult: '选择分析结果',
		selectStatus:'选择分析状态',
		startDate: '开始日期',
		endDate: '结束日期',
		batchDownload: '批量下载',
		sureDownload: '确认批量下载',
		index: '序号',
		fileName: '文件名',
		score: '分数',
		result: '分析结果',
		submitTime: '提交时间',
		analysisTime: '分析时间',
		status: '状态',
		size: '大小',
		action: '操作',
		preview: '预览结果',
		delete: '删除',
		download: '下载',
		// 移除操作
		deleteTip: '删除文件',
		deleteText: '删除的文件暂不支持找回，确定要删除当前文件吗？',
		sure: '删除',
		cancel: '取消',
		search: '查询',
		select: '请选择需要批量下载的复选框',
		

		// startDateText:'选择提交起始日期',
		// endDateText:'选择提交截止日期',
		// refresh:'刷新',
		// updateNumber:'更新时间',
		// analysisStatus:'分析状态',
		// completed:'已完成',
		// submitted:'已提交',
		// falied:'失败',
		// view:'查看',
		// download:'下载',
		// close:'关闭',
		// edit:'编辑',
		// delete:'删除',
		// finish:'完成',
		// deleteSure:'确定要删除吗?',
		// deleted:'删除成功！',
		// downloadFileSuccess:'下载完成',
		// downloadSuccess:'下载完成，请选择要保存的目录',
		// downloadFailed:'下载失败',
		// loading:'加载中…',
		// unavailable:'暂无数据',
		// noPermission:'您没有权限处理该申请单',
	},
	about: {
		name: '产品名称：',
		model: '型号规格：',
		version: '发布版本：',
		fullVersion: '完整版本：',
		manufacturer: '制造商：',
		ch: '苏州可帮基因科技有限公司',
		companyAddressTitle: '住所：',
		companyAddress: '张家港市冶金工业园（锦丰镇）锦兴路26号8号楼一层、二层',
		manufacturerAddress: '生产地址：',
		building1: '张家港市冶金工业园（锦丰镇）锦兴路26号8号楼一层、二层',
	},
	record: {
		pleaseSelect: '选择操作类型',
		startDate: '开始日期',
		endDate: '结束日期',
		index: '序号',
		ip: '操作人ip',
		actionType: '操作类型',
		time: '操作时间',
		clientInfo: '客户端信息',
		signIn: '登录',
		signOut: '登出',
		desc: '操作描述',
		search: '查询',
		upload: '上传样本文件',
		cancelUp: '取消上传',
		verify: '校验文件',
		preview: '预览结果',
		download: '下载结果',
		dele: '删除分析结果'


		// chooseDate:'选择日期',
		// choose:'请选择',
		// refreshToken:'刷新凭证',
		// type:'操作类型',
		// time:'操作时间',
		// create:'创建分析记录',
		// edit:'编辑申请单',
		// upload:'上传数据',
		// stopUpload:'撤销上传',
		// downResult:'下载分析结果',
		// deleteRequest:'删除分析记录',
		// requestNumber:'申请单编号',
	},
	notFound: {
		h1: '抱歉！要访问的页面不存在',
		h2: '让医生有力量，让患者有希望',
		p: '秒之后自动跳转',
		span: '您还可以：',
		gobrow: '浏览官网',
		goBack: '返回上一页'
	}
}