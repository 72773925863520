// i18n.js
import { createI18n } from 'vue-i18n';
 

const i18n = createI18n({
  locale: 'en', // set default locale
  fallbackLocale: 'zh', // set fallback locale
  messages:{
		'zh': require('../components/language/zh'),
		'en': require('../components/language/en')
	}
});
 
export default i18n;