export default {
	//自定义时间过滤器 具体时分
	dateFormat(originVal) {
		if (originVal == null || originVal === '') {
			return '--';
		}
		const date = new Date(originVal)
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
		var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
		// var s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds(); 
		return Y + M + D + h + m;
	},
	// 文件size大小KB MB GB过滤
	formatbytes(bytes) {
		if (bytes === 0) return '0 B';
		var k = 1024, // or 1024
			sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));

		return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
	}
}
