import { createStore } from 'vuex';

// 工程数据赋值
export default createStore ({
	//定义属性
	state(){
		return{
			clientIP: '',
			clientMac: '',
			redTip:false,
			watchOnLine:'',
			screenWidth:'',
			screenHeight:'',
			logOut:true, 
			refresh:true,
		}
	},
	//get方法取值 调用时  this.$store.getters.getClientIP
	getters: {
		getClientIP: state => state.clientIP,
		getClientMac: state => state.clientMac,
		getRedTip: state => state.redTip,
		getWatchOnLine: state => state.watchOnLine,
		getScreenWidth: state => state.screenWidth,
		getScreenHeight: state => state.screenHeight,
		getLogOut: state => state.logOut,
		getRefresh: state => state.refresh,
	},
	//set方法赋值  调用时  that.$store.commit('putClientIP', item);
	mutations: {
		putClientIP(state, clientIP) {
			state.clientIP = clientIP;
		},
		putClientMac(state, clientMac) {
			state.clientMac = clientMac;
		},
		putRedTip(state, redTip) {
			state.redTip = redTip;
		},
		putWatchOnLine(state, watchOnLine) {
			state.watchOnLine = watchOnLine;
		},
		putScreenWidth(state, screenWidth) {
			state.screenWidth = screenWidth;
		},
		putScreenHeight(state, screenHeight) {
			state.screenHeight = screenHeight;
		},
		putLogOut(state, logOut) {
			state.logOut = logOut;
		},
		putRefresh(state, refresh) {
			state.refresh = refresh;
		}
	}
})
