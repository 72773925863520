import {
	createApp
} from 'vue'
import axios, {
	AxiosInstance
} from 'axios';
import {
	ElMessage,
	ElMessageBox,
	ElNotification
} from 'element-plus';

import Cookies from 'js-cookie';
import i18n from './i18n.js';
import store from './store.js'; //vuex状态管理器

// other.js
// import {
// 	useI18n
// } from 'vue-i18n';
// const { t } = useI18n();
// console.log(t);


import global from './common.vue';
// 请求地址
axios.defaults.baseURL = global.baseUrl;
//请求超时60s
axios.defaults.timeout = 60000;
// // 创建axios实例
const service = axios.create({
	baseURL: global.baseUrl,
	timeout: axios.defaults.timeout,
	withCredentials: true
})

const mes = i18n.global.messages['en'];
// 设置显示刷新和退出页
// store.commit('putLogOut', false);

/* 是否有请求正在刷新token */
window.isRefreshing = false
/*被挂起的请求数组*/
let resolveArray = [];
/*push所有请求到数组中*/
function pushResolveArray(cb) {
	resolveArray.push(cb);
}
/*刷新请求（resolveArray数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onRrefreshed(token) {
	resolveArray.map(cb => cb(token));
}
// http request拦截器 添加一个请求拦截器
service.interceptors.request.use(function(config) {
	//当前是否有网络
	if (navigator.onLine == true) {
		let accessToken = Cookies.get('accessToken');
		let scope = Cookies.get('scope')
		config.headers['Content-Type'] = 'application/json;charset=UTF-8';
		//请求刷新接口不需要另外刷新
		if (config.url == "/oauth/refreshToken") {
			return config;
		}
		//请求退出登录接口，不需要刷新token，但也要有header认证
		if (config.url == "/oauth/logout") {
			config.headers.Authorization = 'Bearer' + ' ' + accessToken;
			return config;
		}
		if (accessToken) {
			if (scope != undefined && (scope.indexOf('dpias') >= 0)) {
				if (config.url != "/oauth/refreshToken") {
					config.headers.Authorization = 'Bearer' + ' ' + accessToken;
				}
				let expires = Cookies.get('expires');
				let nowTime = new Date().getTime() / 1000;
				let cha = expires - nowTime;
				// 刷新token接口注释掉 超时未超过五分钟
				if ((cha <= 300) && (config.url != "/oauth/refreshToken")) {
					if (!window.isRefreshing) {
						// 标志改为true，表示正在刷新
						window.isRefreshing = true;
						service.post("/oauth/refreshToken", {
								refreshToken: Cookies.get('refreshToken')
							})
							.then(res => {
								if (res.data.accessToken) {
									onRrefreshed(res.data.accessToken);
									resolveArray = [];
									Cookies.set('expires', res.data.expires, {
										domain: global.domain,
										expires: 1,
										path: '',
										secure: global.httpsSecure
									});
									Cookies.set('scope', res.data.scope, {
										domain: global.domain,
										expires: 1,
										path: '',
										secure: global.httpsSecure
									});
									Cookies.set('accessToken', res.data.accessToken, {
										domain: global.domain,
										expires: 1,
										path: '',
										secure: global.httpsSecure
									});
									Cookies.set('refreshToken', res.data.refreshToken, {
										domain: global.domain,
										expires: 1,
										path: '',
										secure: global.httpsSecure
									});
								}
							})
							.catch(function(error) {})
							.finally(() => {
								window.isRefreshing = false;
							});
						let retry = new Promise((resolve, reject) => {
							pushResolveArray((token) => {
								config.headers.Authorization = 'Bearer' + ' ' + token;
								resolve(config)
							})
						})
						return retry;
					} else {
						let retry = new Promise((resolve, reject) => {
							pushResolveArray((token) => {
								config.headers.Authorization = 'Bearer' + ' ' + token;
								resolve(config)
							})
						})
						return retry;
					}
				}

			} else {
				Cookies.remove('accessToken', {
					path: '',
					domain: global.domain
				});
				Cookies.remove('refreshToken', {
					path: '',
					domain: global.domain
				});
				Cookies.remove('expires', {
					path: '',
					domain: global.domain
				});
				Cookies.remove('scope', {
					path: '',
					domain: global.domain
				});
				localStorage.removeItem("userName");
				localStorage.removeItem("permission");
				localStorage.removeItem("userId");
				localStorage.removeItem("workflows");
				store.commit('putLogOut', true);
				store.commit('putRefresh', false);
			}
		}
		return config;
	} else {
		config.handled = true;
		ElNotification.error({
			message: mes.error.internet
		});
		return Promise.reject(mes.error.internet);
	}
});

// 添加响应拦截器
service.interceptors.response.use(function(response) {
	//下载
	const headers = response.headers;
	if (headers['content-type'] === 'application/x-download;charset=UTF-8') {
		return response
	}
	if (response.status === 200) {
		if (response.config.responseType == "blob" && (response.data.type == 'application/json')) {
			//文件内容为空，下载失败
			response.data.text().then(function(res) {
				var resParse = JSON.parse(res);
				console.log(resParse);
				ElNotification.error({
					message: resParse.data.desc
				});
				//return Promise.reject(resParse.data.desc);
			}).catch(function(err) {
				console.log(err);
			})
			return Promise.reject("Download failed");
		} else if (response.data.status == 0) {
			return response.data
		} else {
			if (response.data.data.message == 'INVALID_CODE' || response.data.data.message ==
				'INVALID_TOKEN' || response.data
				.data.message == 'EMPTY_TOKEN' || response.data.data.message == 'TOKEN_EXPIRED' || response.data
				.data.message ==
				'INVALID_SIGN' || response.data.data.message == 'INVALID_REFRESH_TOKEN' || response.data.data
				.message ==
				'TOKEN_IP_CONFLICT') {
				Cookies.remove('accessToken', {
					path: '',
					domain: global.domain
				});
				Cookies.remove('refreshToken', {
					path: '',
					domain: global.domain
				});
				Cookies.remove('expires', {
					path: '',
					domain: global.domain
				});
				Cookies.remove('scope', {
					path: '',
					domain: global.domain
				});
				localStorage.removeItem("userName");
				localStorage.removeItem("permission");
				localStorage.removeItem("userId");
				localStorage.removeItem("workflows");
				store.commit('putLogOut', true);
				store.commit('putRefresh', false);

				return Promise.reject(mes.error.loginTimeout);
			} else if (response.data.data.message == 'NO_PERMISSION') {
				let that = this;
				service.get("/me")
					.then(function(res) {
						localStorage.setItem("userName", res.data.user.name);
						localStorage.setItem("permission", JSON.stringify(res.data.permission));
						localStorage.setItem("userId", res.data.user.id);
						localStorage.setItem("workflows", JSON.stringify(res.data.workflows));
					})
					.catch(function(err) {});
				ElNotification.error({
					message: response.data.data.desc
				});
				return Promise.reject(response.data.data.desc);
			} else {
				console.log("拦截器响应返回报错信息：" + response.data.data.desc)
				ElNotification.error({
					message: response.data.data.desc
				});
				return Promise.reject(response.data.data.desc);
			}
		}
	} else {
		ElNotification.error({
			message: response
		});
		return Promise.reject(response);
	}
}, function(error) {
	if (!navigator.onLine && error.config && !error.config.handled) {
		ElNotification.error({
			message: mes.error.internet
		});
		return Promise.reject(mes.error.internet);
	} else if (error.status == 404) {
		// router.push('/notFound');
	} else {
		if (error.config && !error.config.handled) {
			ElNotification.error({
				message: mes.error.requestTimeout
			});
			return Promise.reject(mes.error.requestTimeout);
		}
	}
	return Promise.reject(error);
});

export default service