import '@babel/polyfill';
import Promise from 'es6-promise';
Promise.polyfill();
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router/index'
import $ from 'jquery'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import enLocale from 'element-plus/es/locale/lang/en'
import i18n from './mix/i18n.js';
import global from './mix/common.vue'
import filters from "./mix/filter.js" //过滤器
import store from './mix/store.js'; //vuex状态管理器

// axios.defaults.baseURL="http://auth.test.canhelpdx.com/auth";

const app = createApp(App);
app.use(ElementPlus, {
  locale: enLocale,
})
app.use(router);
app.use(i18n);
app.use(store);
app.config.globalProperties.COMMON = global;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
app.config.globalProperties.$filters = filters;
app.mount('#app')


// router.onError((error) => {
// 	const pattern = /Loading chunk (\d)+ failed/g;
// 	const isChunkLoadFailed = error.message.match(pattern);
// 	console.log(router);
// 	const targetPath = router.history.pending.fullPath;
// 	if (isChunkLoadFailed) {
// 		router.replace(targetPath);
// 	}
// });
