const routes = [{
		path: '/',
		name: 'Home',
		redirect: '/uploadImage',
		component: () => import('@/pages/home.vue'),
		children: [{
				path: '/introduce',
				name: 'Introduce',
				component: () => import('@/pages/introduce'),
				meta: {
					menuId: '1-1'
				}
			},
			{
				path: '/uploadImage',
				name: 'UploadImage',
				component: () => import('@/pages/uploadImage'),
				meta: {
					menuId: '1-2',
					keepAlive: true,
				}
			},
			{
				path: '/list',
				name: 'List',
				component: () => import('@/pages/list'),
				meta: {
					menuId: '1-3'
				}
			},
			{
				path: '/about',
				name: 'About',
				component: () => import('@/pages/about'),
				meta: {
					menuId: '1-4'
				}
			},
			// 日志
			// 工程设置-项目文件
			{
				path: '/loginRecord',
				name: 'LoginRecord',
				component: () => import('@/pages/loginRecord'),
				meta: {
					menuId: '1-5',
					menuName: "登录日志",
				}
			}, {
				path: '/handleRecord',
				name: 'HandleRecord',
				component: () => import('@/pages/handleRecord'),
				meta: {
					menuId: '1-6',
					menuName: "操作日志",
				}
			},
			//退出登录
			// {
			// 	path: '/out',
			// 	name: 'out',
			// 	component: () => import('@/components/logOut'),
			// },

		]
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/"
	},
]

export default routes
