<template>
	<router-view></router-view>
</template>

<script>
	import Cookies from 'js-cookie';
	import {
		FunctionGet,
		FunctionPost,
		FunctionDelete,
		FunctionPut
	} from "./mix/apis/index.js";

	export default {
		name: 'App',
		mounted() {
			this.getCode();
 		},
		methods: {
			getCode() {
				let that = this;
				var arr = window.location.search.substr(1).split("=");
				if (Cookies.get("accessToken")) {
					that.$store.commit('putLogOut', false);
					that.$store.commit('putRefresh', true);
				} else {
					if (arr.includes("code")) {
						var https = window.location.href;
						var cs = https.split("?")[1];
						var cs_arr = cs.split("&");
						var cs = {};
						for (var i = 0; i < cs_arr.length; i++) {
							cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
						}
						localStorage.removeItem("userName");
						FunctionGet({
								url: "/oauth/codeExchange/" + cs.code
							})
							.then(function(res) {
								if (res.data.accessToken) {
									that.$store.commit('putLogOut', false);
									that.$store.commit('putRefresh', true);
									Cookies.set("expires", res.data.expires, {
										domain: that.COMMON.domain,
										expires: 1,
										path: "",
										secure: that.COMMON.httpsSecure
									});
									Cookies.set("scope", res.data.scope, {
										domain: that.COMMON.domain,
										expires: 1,
										path: "",
										secure: that.COMMON.httpsSecure
									});
									Cookies.set("accessToken", res.data.accessToken, {
										domain: that.COMMON.domain,
										expires: 1,
										path: "",
										secure: that.COMMON.httpsSecure
									});
									Cookies.set("refreshToken", res.data.refreshToken, {
										domain: that.COMMON.domain,
										expires: 1,
										path: "",
										secure: that.COMMON.httpsSecure
									});
									that.getMeData();
								}
							})
							.catch(function(err) {
								that.$store.commit('putLogOut', true);
								that.$store.commit('putRefresh', false);
							});
					} else {
						 that.$store.commit('putLogOut', true);
						 that.$store.commit('putRefresh', false);
						 console.log("getRefresh:"+that.$store.getters.getRefresh)
					}
				}
			},
			getMeData() {
				FunctionGet({
						url: "/me"
					})
					.then(function(res) {
						localStorage.setItem("userName", res.data.user.name);
					})
					.catch(function(err) {});
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		background-color: #F2F7FE;
	}
	.warning-row {
		background: rgba(253, 246, 236, 0.5) !important;
	}
	
	.success-row {
		background:rgba(64, 158, 255, 0.1) !important;
	}
	.el-range-editor.el-input__wrapper{
		box-sizing: border-box;
		padding: 0 !important;
	}
	.el-range-editor .el-icon{
		padding-left: 5px !important;
	}
	
</style>
