<template>
	<div></div>
</template>
<script>
	// 为true0本地，1线上测试，2正式环境
	// npm run build:static  静态环境
	// npm run build:production  正式环境
	// npm run build:development  测试环境
	// console.log(process.env.NODE_ENV);
	var isDev = 1;
	var power = "zh";
	var version = "";
	
	if (isDev == 0) {
		var baseUrl = 'https://msi.canhelpgenomics.com/api';
		var dpiasUrl = "http://dpias.dev.canhelpdx.com";
		var canhelpUrl = "http://auth.dev.canhelpdx.com/login";
		var canChangeUrl = "http://auth.dev.canhelpdx.com/changePwd";
		var domain = ".dev.canhelpdx.com";
		var clientId = "c3GTthN5SkXhWQGe";
		var httpsSecure = false;
	} else if (isDev == 1)  {
		var baseUrl = 'https://msi.canhelpgenomics.com/api';
		var dpiasUrl = "https://msi.canhelpgenomics.com";
		var canhelpUrl = "https://auth.canhelpgenomics.com";
		var canChangeUrl = "https://auth.canhelpgenomics.com/changePwd";
		var domain = ".canhelpgenomics.com";
		var clientId = "c3GTthN5SkXhWQGe";
		var httpsSecure = true;
	}else if (isDev == 2)  {
		var baseUrl = 'https://msi.canhelpgenomics.com/api';
		var dpiasUrl = "https://msi.canhelpgenomics.com";
		var canhelpUrl = "https://auth.canhelpgenomics.com/login";
		var canChangeUrl = "https://auth.canhelpgenomics.com/changePwd";
		var domain = ".canhelpgenomics.com";
		var clientId = "c3GTthN5SkXhWQGe";
		var httpsSecure = true;
	}

	// console.log = (function (log) {
	// 	return isDev ===0? log : function() {}
	// }(console.log))
	
	// 暴露出这些属性和方法
	export default {
		name: "Common",
		isDev,
		power,
		version,
		baseUrl,
		dpiasUrl,
		canhelpUrl,
		canChangeUrl ,
		domain ,
		clientId ,
		httpsSecure
	};
</script>
<style scoped>
</style>
